import React from "react";
import { graphql } from "gatsby";
import Header from "../components/Header";
import SEO from "../components/seo";
import Post from "../components/Post";
import { ISEOProps, Theme } from "../types/common";

interface IProps {
  data: any;
  pageContext: {
    theme: Theme;
  };
}
const Container = ({ data, pageContext: { theme } }: IProps) => {
  const post = data.markdownRemark;
  const otherPostsInSeries = data.allMarkdownRemark.edges;

  const thumbNailImgFluid = post.frontmatter.thumbNail
    ? post.frontmatter.thumbNail.childImageSharp.fluid
    : null;

  const postH1List = post.headings || [];

  const seoData: ISEOProps = {
    title: post.frontmatter.title,
    description:
      postH1List.length > 0
        ? postH1List.map(item => item.value).join("\n")
        : post.frontmatter.title,
    imageUrl: thumbNailImgFluid ? thumbNailImgFluid.src : null,
  };

  return (
    <Header theme={theme}>
      <SEO {...seoData} />

      <Post post={post} theme={theme} otherPostsList={otherPostsInSeries} />
    </Header>
  );
};

export const query = graphql`
  query($slug: String!, $series: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        theme
        slug
        prefix(formatString: "YYYY.MM.DD")
        series
        seriesDescription
      }
      frontmatter {
        title
        thumbNail {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
        subTitle
      }
      headings(depth: h1) {
        value
      }
    }
    allMarkdownRemark(filter: { fields: { series: { eq: $series } } }) {
      edges {
        previous {
          id
          fields {
            slug
            prefix(formatString: "YYYY.MM.DD")
          }
          frontmatter {
            title
          }
        }
        node {
          fields {
            slug
          }
        }
        next {
          id
          fields {
            slug
            prefix(formatString: "YYYY.MM.DD")
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default Container;
